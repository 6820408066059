import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import { sortDescWith } from "@shared/utils/sort"
import type { AppState } from "../store"
import { saveDealRemote } from "../actions/saveDealRemote"
import { acceptDealOffer } from "../actions/acceptDealOffer"
import { declineDealOffer } from "../actions/declineDealOffer"
import { markDealOfferViewed } from "../actions/openDealOffer"
import { updateDealOnboarding } from "../actions/updateDealOnboarding"
import { initiateDealOnboarding } from "../actions/initiateDealOnboarding"
import { SHOPPING_CART_CURRENCY } from "@shared/sales-platform/constants"
import {
  DealFinancingGrantedStatus,
  DealFinancingStatus,
  DealJSON,
  DealOnboardingStatus
} from "@shared/mongodb-schema/lib/sales-platform/schemas/deal/types"
import {
  PlatformJSON,
  ShoppingCartCurrencyType
} from "@shared/mongodb-schema/lib/sales-platform/schemas/platform/types"
import { OfferJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/offer/types"

export interface DealFinancing {
  state: DealFinancingStatus
  granted: DealFinancingGrantedStatus
  loanPeriod: number
  downpayment: number
  loanAmount: number
  monthlyPayment: number
  interest: number
  administrationFee: number
  invoiceFee: number
}

export interface DealDealer {
  name: string
  location: string
  address: string
}

export interface DealNewCar {
  registrationNumber: string
  thumbnailUrl: string
  brand: string
  bodyStyle: string
  model: string
  carId: string
  productIntro: string
  price: number
  subCategory: string
  currentKm: string
  emission: string
  fuelType: string
  modelYear: number
  transmission: string
  carLink: string
  wdType: string
  engineSize: number
}

interface PlatformSettings {
  primaryColor?: string
  secondaryColor?: string
  logoUrl?: string
  extraFinancingOptions?: {
    ltr?: boolean
  }
  currencyType?: ShoppingCartCurrencyType
}

interface DealSlice {
  owner: string
  dealId: string
  tradeinCar: string[]
  interestCount: number
  newCar: DealNewCar
  dealer: DealDealer
  financing: DealFinancing
  offers: { [offerId: string]: OfferJSON }
  hasFinancingChanged: boolean
  saveDealLoading: boolean
  onboardingLoading: boolean
  isOfferAccepted: boolean
  onboardingStatus: DealOnboardingStatus
  currencyType?: ShoppingCartCurrencyType
  chatExpanded: boolean
  tradeInExpanded: boolean
  financingExpanded: boolean
  platform: PlatformSettings | null
}

export const FINANCING_STATUS = {
  UNSET: "UNSET",
  APPLY_LTR: "APPLY_LTR",
  APPLY_OP: "APPLY_OP",
  APPLY_DEALER: "APPLY_DEALER",
  ALREADY_GRANTED: "ALREADY_GRANTED",
  NO_NEED: "NO_NEED"
} as const

export const LTR_STATUS = {
  NOT_APPLIED: "NOT_APPLIED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED"
} as const

export const ONBOARDING_STATUS = {
  NEW: "NEW",
  TRADEIN: "TRADEIN",
  LOGIN: "LOGIN",
  FINANCING: "FINANCING",
  CHAT: "CHAT",
  DONE: "DONE"
} as const

export const DEAL_TYPE = {
  NEW: "NEW",
  TRADE: "TRADE",
  BUY: "BUY"
} as const

const initialState: DealSlice = {
  owner: "",
  dealId: "",
  tradeinCar: [],
  interestCount: 0,
  newCar: {
    registrationNumber: "",
    thumbnailUrl: "",
    brand: "",
    bodyStyle: "",
    model: "",
    carId: "",
    productIntro: "",
    price: 0,
    subCategory: "",
    currentKm: "",
    emission: "",
    fuelType: "",
    modelYear: 0,
    transmission: "",
    carLink: "",
    wdType: "",
    engineSize: 0
  },
  dealer: {
    name: "",
    location: "",
    address: ""
  },
  financing: {
    state: FINANCING_STATUS.UNSET,
    granted: LTR_STATUS.NOT_APPLIED,
    loanPeriod: 0,
    downpayment: 0,
    loanAmount: 0,
    monthlyPayment: 0,
    interest: 0,
    administrationFee: 0,
    invoiceFee: 0
  },
  offers: {},
  hasFinancingChanged: false,
  financingExpanded: false,
  saveDealLoading: false,
  onboardingLoading: false,
  isOfferAccepted: false,
  onboardingStatus: ONBOARDING_STATUS.NEW,
  currencyType: SHOPPING_CART_CURRENCY.EUR,
  chatExpanded: false,
  platform: null,
  tradeInExpanded: false
}

export const subscribeDealUpdates = createAction<string>(
  "chat/subscribeDealUpdates"
)
export const subscribeDealUpdatesSuccess = createAction<string>(
  "chat/subscribeDealUpdatesSuccess"
)
export const subscribeDealUpdatesFailed = createAction<{
  dealId: string
  error: string
}>("chat/subscribeDealUpdatesFailed")

export const unsubscribeDealUpdates = createAction<string>(
  "chat/unsubscribeDealUpdates"
)
export const unsubscribeDealUpdatesSuccess = createAction<string>(
  "chat/unsubscribeDealUpdatesSuccess"
)
export const unsubscribeDealUpdatesFailed = createAction<{
  dealId: string
  error: string
}>("chat/unsubscribeDealUpdatesFailed")

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    clearDealState: () => initialState,
    setDealData(state, action: PayloadAction<DealJSON>) {
      state.owner = action.payload.owner || ""
      state.dealId = action.payload.dealId
      state.newCar = mapNewCar(action.payload.newCar)
      state.tradeinCar = action.payload.tradeinCar
      state.dealer = action.payload.dealer
      state.financing = mapFinancing(action.payload.financing)
      state.isOfferAccepted = Boolean(action.payload.acceptedOfferId)
      state.onboardingStatus = action.payload.onboardingStatus
      state.currencyType =
        action.payload.currencyType ?? SHOPPING_CART_CURRENCY.EUR
    },
    setInterestCount(state, action) {
      state.interestCount = action.payload
    },
    setFinancingStatus: (state, action: PayloadAction<DealFinancingStatus>) => {
      state.financing.state = action.payload
    },
    setFinancingExpanded(state, action: PayloadAction<boolean>) {
      state.financingExpanded = action.payload
    },
    setFinancingChanged: (state, action: PayloadAction<boolean>) => {
      state.hasFinancingChanged = action.payload
    },
    setTradeInExpanded(state, action: PayloadAction<boolean>) {
      state.tradeInExpanded = action.payload
    },
    setChatExpanded(state, action: PayloadAction<boolean>) {
      state.chatExpanded = action.payload
    },
    setOffers: (state, action: PayloadAction<OfferJSON[]>) => {
      state.offers = {}
      for (const offer of action.payload) {
        state.offers[offer._id] = offer
      }
    },
    addOffer: (state, action: PayloadAction<OfferJSON>) => {
      state.offers[action.payload._id] = action.payload
    },
    removeOffer: (state, action: PayloadAction<string>) => {
      delete state.offers[action.payload]
    },
    setPlatformSettings: (state, action: PayloadAction<PlatformJSON>) => {
      state.platform = {
        primaryColor: action.payload.primaryColor,
        secondaryColor: action.payload.secondaryColor,
        logoUrl: action.payload.logoUrl,
        extraFinancingOptions: action.payload.extraFinancingOptions,
        currencyType: action.payload.currencyType
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(updateDealOnboarding.pending, (state) => {
      state.onboardingLoading = true
    })

    builder.addCase(updateDealOnboarding.fulfilled, (state, action) => {
      state.onboardingStatus = action.payload.deal.onboardingStatus
      state.onboardingLoading = false
    })

    builder.addCase(updateDealOnboarding.rejected, (state) => {
      state.onboardingLoading = false
    })

    builder.addCase(initiateDealOnboarding.pending, (state) => {
      state.onboardingLoading = true
    })

    builder.addCase(initiateDealOnboarding.fulfilled, (state, action) => {
      state.onboardingStatus = action.payload.deal.onboardingStatus
      state.onboardingLoading = false
    })

    builder.addCase(initiateDealOnboarding.rejected, (state) => {
      state.onboardingLoading = false
    })

    builder.addCase(saveDealRemote.pending, (state) => {
      state.saveDealLoading = true
    })

    builder.addCase(saveDealRemote.rejected, (state) => {
      state.saveDealLoading = false
    })

    builder.addCase(saveDealRemote.fulfilled, (state, action) => {
      if (action.payload.tradeInCar) {
        state.tradeinCar = [action.payload.tradeInCar._id]
      } else {
        state.tradeinCar = []
      }
      if (action.payload.financing) {
        state.financing = {
          ...state.financing,
          ...mapFinancing(action.payload.financing)
        }
      } else {
        state.financing.state = FINANCING_STATUS.NO_NEED
      }
      state.hasFinancingChanged = false
      state.saveDealLoading = false
    })

    builder.addCase(acceptDealOffer.fulfilled, (state, action) => {
      state.isOfferAccepted = Boolean(action.payload.deal.acceptedOfferId)
      state.offers[action.payload.offer._id] = action.payload.offer
    })

    builder.addCase(declineDealOffer.fulfilled, (state, action) => {
      state.offers[action.payload.offer._id] = action.payload.offer
    })

    builder.addCase(markDealOfferViewed.fulfilled, (state, action) => {
      const offerId = action.meta.arg
      if (state.offers[offerId]) {
        state.offers[offerId].firstViewedAt = action.payload
      }
    })
  }
})

function mapNewCar(newCar: DealJSON["newCar"]): DealNewCar {
  return {
    ...newCar,
    bodyStyle: newCar.bodyStyle ?? "",
    wdType: newCar.wdType ?? "",
    engineSize: newCar.engineSize ?? 0,
    carLink: newCar.carLink ?? "",
    subCategory: newCar.subCategory ?? "",
    productIntro: newCar.productIntro ?? ""
  }
}

function mapFinancing(financing: DealJSON["financing"]): DealFinancing {
  return {
    ...financing,
    loanPeriod: financing.loanPeriod ?? 0,
    downpayment: financing.downpayment ?? 0,
    loanAmount: financing.loanAmount ?? 0,
    monthlyPayment: financing.monthlyPayment ?? 0,
    interest: financing.interest ?? 0,
    administrationFee: financing.administrationFee ?? 0,
    invoiceFee: financing.invoiceFee ?? 0
  }
}

export const {
  clearDealState,
  setDealData,
  setInterestCount,
  setFinancingStatus,
  setFinancingExpanded,
  setFinancingChanged,
  setTradeInExpanded,
  setChatExpanded,
  setOffers,
  addOffer,
  removeOffer,
  setPlatformSettings
} = dealSlice.actions

const getDealSlice = (state: AppState) => state.deal

export const selectDealNewCar = createSelector(
  getDealSlice,
  (deal) => deal.newCar
)

export const selectDealDealer = createSelector(
  getDealSlice,
  (deal) => deal.dealer
)

export const selectInterestCount = createSelector(
  getDealSlice,
  (deal) => deal.interestCount
)

export const selectMonthlyPayment = createSelector(
  getDealSlice,
  (deal) => deal.financing.monthlyPayment
)

export const selectFinancingState = createSelector(
  getDealSlice,
  (deal) => deal.financing.state
)

export const selectFinancingGranted = createSelector(
  getDealSlice,
  (deal) => deal.financing.granted
)

export const selectHasChangedFinancing = createSelector(
  getDealSlice,
  (deal) => deal.hasFinancingChanged
)

export const selectFinancing = createSelector(
  getDealSlice,
  (deal) => deal.financing
)

export const selectSaveDealLoading = createSelector(
  getDealSlice,
  (deal) => deal.saveDealLoading
)

export const selectOnboardingLoading = createSelector(
  getDealSlice,
  (deal) => deal.onboardingLoading
)

export const selectTradeInCarId = createSelector(
  getDealSlice,
  (deal) => deal.tradeinCar[0]
)

export const selectDealId = createSelector(getDealSlice, (deal) => deal.dealId)

const selectOffersObject = createSelector(getDealSlice, (deal) => deal.offers)

export const selectOffers = createSelector(selectOffersObject, (offers) =>
  Object.values(offers).sort(
    sortDescWith((offer) => new Date(offer.createdAt).valueOf())
  )
)

export const selectOfferById = createSelector(
  [selectOffersObject, (_, offerId: string) => offerId],
  (offers, offerId) => offers[offerId] ?? null
)

export const selectIsOfferViewed = createSelector(selectOfferById, (offer) =>
  Boolean(offer.firstViewedAt)
)

export const selectIsDealOfferAccepted = createSelector(
  getDealSlice,
  (deal) => deal.isOfferAccepted
)

export const selectDealHasOwner = createSelector(getDealSlice, (deal) =>
  Boolean(deal.owner)
)

export const selectDealOnboardingStatus = createSelector(
  getDealSlice,
  (deal) => deal.onboardingStatus
)

export const selectIsChatExpanded = createSelector(
  getDealSlice,
  (deal) => deal.chatExpanded
)

export const selectIsFinancingExpanded = createSelector(
  getDealSlice,
  (deal) => deal.financingExpanded
)

export const selectIsTradeInExpanded = createSelector(
  getDealSlice,
  (deal) => deal.tradeInExpanded
)

export const selectPlatformSettings = createSelector(
  getDealSlice,
  (deal) => deal.platform
)

export const selectExtraFinancingSettings = createSelector(
  selectPlatformSettings,
  (platform) => platform?.extraFinancingOptions
)

export const selectDealCurrencyType = createSelector(
  getDealSlice,
  selectPlatformSettings,
  (deal, platform) =>
    deal.currencyType || platform?.currencyType || SHOPPING_CART_CURRENCY.EUR
)

export default dealSlice.reducer
